import React from 'react';
import '../../css/main.css';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


function NavBar() {
    return (
        <Navbar expand="lg" id="navbar">
            <Navbar.Toggle  />
            <Navbar.Collapse>
                <Nav className="mr-auto justify-content-start">
                    <Link to='/' className='nav-link'>HOME</Link>
                    <Link to='/pay' className='nav-link'>PAY HERE</Link>
                    <NavDropdown title="TIME MACHINE">
                        <NavDropdown.Item>2018 REUNION - COMING SOON</NavDropdown.Item>
                        <NavDropdown.Item>2016 REUNION - COMING SOON</NavDropdown.Item>
                    </NavDropdown>
                    <Link to='/activities' className='nav-link'>2020 REUNION ACTIVITIES</Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default NavBar;
