import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function activitiesPage(){

    return(
        <div>
            <div id="activitiesPageContentDiv1">
                <h2>ON SITE</h2>
                <br/>

                <h5>Lake Erie Canopy Tours</h5>
                (<a href="https://www.lakeeriecanopytours.com/tours-and-pricing" target="_blank">click here for more info about <strong>Canopy Tours</strong></a>)
                <div>Guided Zip Line Tour - $49-$89</div>
                <div>Adventure Course (2 hours, 30+ obstacles) - $49</div>
                <div>Kids Adventure Course - $19</div>
                <div>Boat Rentals - $25-$40/hr </div>
                <br/>

                <h5> Bike Rental - FREE</h5>
                (<a href="https://www.thelodgeatgeneva.com/play/bike-rental" target="_blank">click here for more info about <strong>Bike Rentals</strong></a>)
                <br/>
                <br/>

                <h5>Winery Tours</h5>
                (<a href="https://www.thelodgeatgeneva.com/play/wine-shuttle" target="_blank">click here for more info about <strong>Wine Tours</strong></a>)
                <div> Signature Wine Tour (4 wineries) - $35- $75</div>
                <div> Deluxe Wine Shuttle - $35-$45</div>
            </div>
            <br/>
            <br/>
            <br/>

            <div id="activitiesPageContentDiv2">
                <h2>OFF SITE</h2>
                <br/>
                <h5>Adventure Zone</h5>
                (<a href="https://www.adventurezonefun.com" target="_blank">click here for more info about <strong>Adventure Zone</strong></a>)
                <div>Extreme Adventure - $21 (includes 6 activities, rides)</div>
                <div>Kids Extreme - $16.75 (includes 8 activities, rides) </div>
                <br/>
                <h5> Mini Golf </h5>
                (<a href="https://www.allisonsminigolf.com/index.html" target="_blank">click here for more info about <strong>Mini Golf</strong></a>)
                <br/>
                <br/>
                <h5>Best Coast Water Sports</h5>
                (<a href="https://www.bestcoastwatersports.com" target="_blank">click here for more info about <strong>Water Sports</strong></a>)
                <div>Jet Ski (License or Cert required) - $110/hr </div>
                <div>Stand up Paddle Board - $25/hr</div>
                <div>Paddle Board Lesson - $15</div>
                <div>Kayak Rental (single)- $25/hr</div>
                <div>Kayak Rental (tandem)- $40/hr</div>
            </div>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

        </div>
    )
}

export default activitiesPage;