import React from 'react';

import NavBar from '../NavBar/NavBar';
import FamilyBanner from '../FamilyBanner/FamilyBanner';
import RoutedContent from '../RoutedContent/RoutedContent';


function App() {
    return (
        <div>
            <NavBar />
            <div className="page-wrapper">
                <FamilyBanner />
                <RoutedContent />
            </div>
        </div>
    );
}

export default App;
