import React from 'react';
import {Link} from 'react-router-dom';

/**Image imports**/
import theLodge from '../../images/thelodge.jpg';
import roomBlockInfo from '../../images/roomblockinfo.png';
import davisFamilyReunion2018 from '../../images/davisfamilyreunion2018.jpg';

function HomePage(){
    return(
        <div>
            <img src={theLodge} alt="The Lodge at Geneva-on-the-Lake" className="lodgeimages" />
            <h2 className="header main-title">GENEVA ON THE LAKE</h2>
            <br/>

            <div className="geneva-text">
                <div>The 2020 Davis Family Reunion will be held at:</div>
                <br/>
                <div>The Lodge at Geneva On The Lake</div>
                <div className="italicize">4888 North Broadway (SR 534)</div>
                <div className="italicize">Geneva on the Lake, OH 44041</div>
                <br/>
                <h6>Click <Link className="underline" to="/pay">HERE</Link> to make your payment for the 2020 Reunion!</h6>
            </div>
            <br/>

            <h2 className="header important-dates">Important dates</h2>
            <div className="important-dates-text">
                <div>Reunion Dates: <strong>July 31st - August 2nd, 2020</strong></div>
                <div>Last Day to reserve a room: <strong>June 16, 2020</strong></div>
            </div>
            <br/>
            <br/>

            <h2 className="header instructions">Instructions for Making Online Reservations</h2>
            <div className="instructions-text">
                <h6>(Reservations are not available over the phone)</h6>
                <ol>
                    <li>Go to <a className="underline" target="_blank" href="https://www.thelodgeatgeneva.com">www.thelodgeatgeneva.com</a></li>
                    <li>Click <strong>“Make a Reservation”</strong> at the top of the home page</li>
                    <li>Enter your Check In and Check Out Dates: <strong>July 31-August 2, 2020</strong></li>
                    <li>Click on the <strong>“Specials Codes”</strong> drop down and then under <strong>“Group Code”</strong> Type in your <strong>Lodge Code: 4GG9D5</strong> to book a lodge; or your <strong>Cottage Code: 4GG8WO</strong> to book a cottage</li>
                    <li>Pick the room you wish to book</li>
                    <li>Fill in your information (At this time, the card you entered <strong className="underline">WILL BE CHARGED FOR THE FIRST NIGHT</strong> stay plus taxes and fees)</li>
                    <li>Click Submit and watch for your <strong>confirmation email</strong></li>
                </ol>
            </div>
            <br/>

            <img src={roomBlockInfo} alt="Room block info" className="roomBlockImage" />
            <div className="room-info-text">
                <div>Note that room prices are for <strong>multiple</strong> occupants</div>
                <div>Contact the planning committee at <strong>ourdavisfamilyinfo@gmail.com</strong> if you need help finding someone to share a room with!</div>
            </div>
            <img src={davisFamilyReunion2018} alt="Family Banner" className="center marginT-XXL full-width" />
        </div>
    );
}

export default HomePage;
