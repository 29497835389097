import React from 'react';
import { Switch, Route } from "react-router-dom";

import HomePage from '../homePage/homePage.js';
import PaymentPage from '../paymentPage/paymentPage';
import ActivitiesPage from '../activitiesPage/activitiesPage.js';

function RoutedContent() {
    return (
        <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/pay" component={PaymentPage}/>
            <Route path="/activities" component={ActivitiesPage} />
        </Switch>
    )
}

export default RoutedContent;
